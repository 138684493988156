.mi-boton {
  padding: 0px 5px 5px 5px;
  border: 0px solid #007bff;
  background-color: transparent;

  /* Estilos cuando el mouse está sobre el botón */
  &:hover {
    border: 2px solid #007bff;
    border-radius: 5px;
  }
}